:root{
    --lightorange-color: #f28f59;
    --orange-color: #eb7d42;
    --blue-color: #28abc5;
    --lightblue-color: #4dc6de;
    --link-color: #037ca3;
}
html{
    font-size: 20px;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
    color: var(--link-color, #037ca3);
    outline: none;
    box-shadow: none;
}
button {
    outline: 0!important;
    -moz-outline-style: none;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper{
    width: 960px;
    margin: 0 auto;
    position: relative;
}

h1 {
    font: 400 36px/40px Roboto, sans-serif;
}
h1, h2, h3, h4, h5{
    color: #444;
    margin: 0 0 10px;
}

p{
    margin: 0 0 20px;
    /*font: 15px/22px Arial, sans-serif;*/
}

.action-btn{
    margin-bottom: 3px;
    outline: none;
    padding: 12px 20px 10px 20px;
    display: inline-block;
    border: 0;
    border-bottom: 3px solid rgba(0,0,0,0.3);
    font: 600 12px/12px sans-serif;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
}

.action-btn:hover{
    color: #FFF;
    text-decoration: none;
}

.action-btn.orange{
    background-color: var(--orange-color, #f28f59);
}
.action-btn.orange:active{
    background-color: var(--lightorange-color, #f28f59);
}

.action-btn.blue{
    background-color: var(--blue-color, #4dc6de);
}
.action-btn.blue:active{
    background-color: var(--lightblue-color, #4dc6de);
}

footer{
    height: 80px;
}
footer .wrapper{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    height: 100%;
}
footer p{
    margin: 0;
}
footer nav span{
    padding-right: 15px;
}
footer nav span:not(:first-child){
    border-left: 1px solid #CCC;
    padding-left: 15px;
}
footer a{
    color: var(--link-color, #037ca3);
    text-decoration: none;
    font-size: 15px;
}

.center-block{
    display: block;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 992px) {
    .wrapper{
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper{
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 420px){
    body, html{
        font-size: 18px;
    }
}
