.login-wrap{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.login-wrap .login-logo{
    height: 3.5rem;
    margin-top: 0.7rem;
}
.login-wrap .login-logo.spin{
    animation: spin 1s infinite linear;
}

.login-wrap .social-login, .login-wrap .login-form{
    margin: 0 auto;
}

.login-wrap .social-login{
    /*margin-top: 50px;*/
}
.login-wrap .social-login h3{
    margin-bottom: 2rem;
    font-size: 1.3rem;
}

.login-wrap .social-login-btn{
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 0.8rem;
    font-weight: 600;
    justify-content: flex-start;
    box-shadow: 0 1.5px 4px 0 rgba(0,0,0,.25);
    width: 100%;
    letter-spacing: .4px;
    min-width: 120px;
    padding: 0 1rem;
    white-space: nowrap;
    border: none;
}


.login-wrap .social-login-btn span{
    width: 100%;
    text-align: center;
}
.login-wrap .facebook-login-link{
    background-color: #4267b2;
    color: #FFF;
}
.login-wrap .facebook-login-link:hover{
    background-color: #35528e;
}

.login-wrap .google-login-link{
    color: #4d4d4d;
    background-color: #FFF;
    margin: 15px 0;
}

.login-wrap .google-login-link:hover{
    background-color: #ededed;
}
.login-wrap .zalo-login-link{
    color: #3c80c7;
    background-color: #FFF;
    margin: 15px 0;
}

.login-wrap .zalo-login-link:hover{
    background-color: #ededed;
}
.login-wrap .social-login-btn>img{
    width: 1.4rem;
    display: inline-block;
    vertical-align: top;
    margin: 0.5rem 0.8rem 0.5rem 0;
}

.login-wrap .login-sep{
    position: relative;
    margin: 24px 0;
    overflow: hidden;
}

.login-wrap .login-sep .sep-label{

    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .4px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding: 0 6px;
    color: #8d959c;
    z-index: 802;
}


.login-wrap .login-sep .sep-label::before,
.login-wrap .login-sep .sep-label::after{
    position: absolute;
    content: "";
    width: 240px;
    height: 1px;
    top: 50%;
    background-color: #e6e8eb;
}

.login-wrap .login-sep .sep-label::after{
    left: 100%;
}

.login-wrap .login-sep .sep-label::before{
    right: 100%;
}

.login-wrap .summary-errors{
    margin-bottom: 20px;
    color: #ed3939; 
}

.login-wrap .login-form .input-field-group:not(:first-child){
    margin-top: 12px;
}

.login-wrap .extra-actions{
    margin: 6px 0;
    text-align: right;
    font-size: 0.65rem;
    line-height: 15px;
}

.login-wrap .extra-actions a{
    color: #8d959c;
}

.login-wrap .extra-actions a:hover{
    color: #58647b
}

.login-wrap .form-foot{
    margin: 25px 0;
}

.login-wrap .submit-btn{
    width: 100%;
    cursor: pointer;
    background-color: #1b9ad1;
    border: none;
    color: #FFF;
    text-transform: UPPERCASE;
    padding: 0.5rem 0.85rem;
    font-size: 0.85rem;
    font-weight: 600;
    letter-spacing: .8px;
    margin-bottom: 15px;
    box-shadow: none;
}
.login-wrap .submit-btn i{
    margin-right: 6px;
}

.login-wrap .submit-btn:hover{

    background-color: #33afe5;
}
.login-wrap .form-foot .signup-text{
    font-size: 0.85rem;
}

.login-wrap .form-foot .signup-text a.signup-link{
    color: #1b9ad1;
}

.login-wrap .form-foot .signup-text a.signup-link:hover{
    color: #1276a1;
}

@keyframes spin{
    0% {
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
