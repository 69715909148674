#dashboard {
  .mt-6 {
    margin-top: 60px !important;
  }
  .mt-7 {
    margin-top: 70px !important;
  }
  .mt-8 {
    margin-top: 80px !important;
  }

  .extrabuttons {
    text-align: center;
  }

  .compensation {
    border-radius: 8px;
    position: relative;
    clear: both;
    min-height: 125px;
  }

  .compensation-border {
    background: linear-gradient(to bottom, white 1%, #fcfcfc 98%) repeat scroll
      0 0 transparent;
    padding: 0;
    margin: 50px 0 0 0;
    height: auto;
    border-top: 3px solid #ccc;
    border-bottom: 1px solid #dfdfdf;
    border-left: 1px solid #ececec;
    border-right: 1px solid #ececec;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  }

  .compensation-content {
    //text-align: center;
    //width: 70%;
    //margin-left: 3%;
    padding-top: 25px;
  }

  .compensation-value{
  }

  .compensation-btn {
    //width: 22%;
    text-align: center;
    position: absolute;
    padding-top: 25px;
    //margin: -25px 0 0;
    //left: auto;
    //right: 25px;
  }
  .label {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 76%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
  }
  .label-primary {
    //background-color: #337ab7;
    background-color: #0e6bac;
  }

  .label-info {
    background-color: #5bc0de;
  }

  .btn-dp-danger {
    color: #ffffff;
    //background: #036;
    background: #ef5350;
    border: 1px solid #ef5350;
  }
  .btn-dp-danger:hover {
    background: #c82333;
    border: 1px solid #c82333;
  }

  .btn-dp-warning {
    color: #ffffff;
    //background: #036;
    background: #ffe082;
    border: 1px solid #ffe082;
  }
  .btn-dp-warning:hover{
    background-color: #ffc107;
    border-color: #ffc107;
  }

  .btn-dp-success {
    color: #ffffff;
    //background: #036;
    background: #28a745;
    border: 1px solid #28a745;
  }
  .btn-dp-success:hover{
    background-color: #1c7430;
    border-color: #1c7430;
  }

  .btn-dp-primary {
    color: #fff;
    //background: #036;
    background: #0e6bac;
    border: 1px solid #0e6bac;
  }
  .btn-dp-primary:hover{
    background-color: #036;
    border-color: #036;
  }

  .btn-dp-default-rev {
    color: #0e6bac;
    //background: #036;
    background: #ffffff;
    border: 1px solid #0e6bac;
  }
  .btn-dp-default-rev:hover {
    background:  rgba(224, 224, 224, 1);
  }

  .btn-survey{
    padding: 5px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
}

.text-expired{
  font-size: 12px !important;
  font-weight: bold;
  color: #c82333
}
.width-table-13{
  width: 13% !important;
}
.width-table-14{
  width: 14% !important;
}
.width-table-15{
  width: 15% !important;
}
.width-table-16{
  width: 16% !important;
}
.width-table-17{
  width: 17% !important;
}
.width-table-18{
  width: 18% !important;
}
.width-table-19{
  width: 19% !important;
}
.width-table-20{
  width: 20% !important;
  min-width: 185px;
}

  .a-dp-default {
    color: #0e6bac;
    text-decoration: underline;
  }

  .btn-width-177{
    width: 177.89px;
  }

  .sp {
    width: 20px;
    height: 20px;
    clear: both;
    display: inline-block;
    margin-left: 15px;
}

.sp-circle {
    border: 4px rgba( #000,0.25) solid;
    border-top: 4px rgba( #000,1) solid;
    border-radius: 50%;
    -webkit-animation: spCircRot .6s infinite linear;
    animation: spCircRot .6s infinite linear;
}
@-webkit-keyframes spCircRot {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(359deg); }
}
@keyframes spCircRot {
    from { transform: rotate(0deg); }
    to { transform: rotate(359deg); }
}
}
