.top-intro{
}

.image-intro{
    position: relative;
    background-image: url('../src/assets/bg.jpg');
    background-size: cover;
    height: 527px;
}
.image-intro.faq{
    background-image: url('../src/assets/faq.jpg');
}
.image-intro.terms{
    background-image: url('../src/assets/terms.jpg');
}
.image-intro.privacy{
    background-image: url('../src/assets/privacy.jpg');
}
.intro-content{
    position: absolute;
    width: 100%;
    top: 145px;
    left: 0;
}
.intro-content h1{
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
    //width: 375px;
    margin-bottom: 20px;
    font-size: 41px;
    line-height: 45px;

}
.wrapper-content{
    width: 50%;
    float: right; 
    margin-top: 10px;
}
.wrapper-content-left{
    text-align: left;
}

.wrapper-content-right{
    text-align: right;
}
.wrapper-content-margin-left{
    margin-left: 20%;
}
.wrapper-content p{
    font-size: 16px;
    line-height: 28px;
    //background-color: rgba(255, 255, 255, 0.75);
    padding: 0 10px;
}
.how{
    margin-top: 40px;
    margin-bottom: 40px;
}
.how .steps{
    display: flex;
    flex-flow: row wrap;
}
.how .steps .step{
    flex-grow: 1;
    text-align: center;
    flex-basis: 0;
}
.how .steps .step h2{
    font: 300 20px/25px Roboto, sans-serif;
    margin-top: 10px;
}
.how .steps .step h2 span{
    display: inline-block;
    background-color: #ebf0f0;
    width: 25px;
    border-radius: 25px;
    margin-right: 5px;
}
.how .steps .step img{
    width: 150px;
}

.join{
    background: #e8ebef url('../src/assets/bg-content.gif') repeat 0 0;
    color: #535f65;
    padding: 45px 0;
}

.join .wrapper{
    display: flex;
    flex-flow: row wrap;
}

.join .wrapper>.block{
    flex: 1;
}

.join .description-block h1{
    font-size: 30px;
}
.join .description-block h1:not(:first-child){
    margin-top: 30px;
}

.join .request-block{
    margin: 0 25px 0 25px;
}

.request-block .request-form{
    background-color: #d3dbe0;
    font-size: 12px;
    color: #444;
    margin: 0;
    padding: 30px;
    border-radius: 12px;
}
.join .request-form label{
    margin-bottom: 5px;
    display: block;
    font-size: 15px;
}
.join .request-form input[type="text"]{
    display: block;
    width: 100%;
    //margin-bottom: 20px;
    font-size: 15px;
   // border: 1px solid #CCC;
    padding: 10px 10px;
    outline: none;
}

.request-block .request-form select{
    display: block;
    margin-bottom: 20px;
    font-size: 15px;
    border: 1px solid #CCC;
    padding: 10px 10px;
    outline: none;
}
.request-block .request-form .register-btn{
    display: block;
}

/*.h1-title{
    font-size: 48px !important;
  }*/

@media screen and (max-width: 992px) {
    .intro-content{
        top: 65px;
    }
    .intro-content h1{
        font-size: 30px;
    }
    .wrapper-content{
        width: 67%;
    }
    .image-intro{
        height: 310px;
    }
}

@media screen and (max-width: 768px) {
    .how .steps .step{
        flex-basis: 50%;
    }
    .how .steps .step img{
        width: 100px;
    }
    .top-logo-text{
        display: none;
    }
    .join .wrapper{
        flex-flow: column wrap;
    }

    .join .request-block {
        margin: 0;
    }
    .join .description-block .content p {
        text-align: justify;
    }

    .intro-content h1{
        background: rgba(0,0,0,0.15);
        padding: 10px 20px;
    }

    .wrapper-content{
        width: 100%;
    }
}
@media screen and (max-width: 540px) {
    .image-intro{
        background-position-x: -125px;
    }
    .intro-content h1{
        margin-left: 10%;
        text-align: right;
    }
}
@media screen and (max-width: 380px) {
    .intro-content h1{
        font-size: 24px;
    }
    .image-intro{
        background-position-x: -165px;
    }
}
