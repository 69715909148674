.top-header{
    //background-color: #525d5e;
    background-color: #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 1000;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.top-header .nav-link:last-child{
    padding-right: 0;
}
.top-header .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.top-header .top-logo{
    color: #FFF;
    font-size: 20px;
    font-family: 'Days One', sans-serif;
    text-decoration: none;
    width: 120px;
}
.top-header .top-logo img{
    margin-right: 15px;
    width: 100%;
}
.top-header .top-nav{
    display: flex;
    align-items: center;
    font-size: 0.85rem;
}
.top-header .top-nav a{
    color: #525d5e;
    text-decoration: none;
}

.top-header .top-nav .action-btn{
    padding: 6px 15px 3px 15px;
    margin-bottom: 0;
}

.top-header .top-nav .login-link{
    margin-left: 20px;
}

.top-header .top-nav .top-login-btn{
 border: 1px solid #0e6bac;
    padding: 0.2rem 1rem;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2);
    color: #0e6bac;
}
.top-header .top-nav .top-login-btn:hover{
    background-color: #0e6bac;
    color: #FFF;
}

.dropdown-item-color{
    color: black !important;
    font-size: 0.85rem;
}

.nav-item-color{
    color: #525d5e !important;
}
.nav-item-point{
    cursor: pointer;
}

.logout-btn{
    cursor: pointer;
}
.dropdown-toggle::after{
    display: none !important;
}
.dropdown-menu{
    right: 0 !important;
    left: auto !important;
    transform: translateY(40px) !important;
}
@media only screen and (max-width: 420px) {
    .top-header .nav-link.name strong{
        display: none;
    }
}
