#privacy{
    ul {
        margin-left: 30px
    }
    ul li{
        margin: 0 0 12px 0;
    }
    h2{
        margin: 30px 0;
    }
    h4{
        margin: 20px 0;
    }
    p{
        margin: 0 0 25px 0;
    }
}