.img-upload-container {
  margin-bottom: 20px;
  position: relative;

  .img-preview-container {
    background-color: #f7f7f7;
  }

  .img-preview {
    background: center center no-repeat;
    background-size: contain;
    margin-bottom: 10px;
    // height: 250px;
    // width: 250px;
  }

  .img-upload {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .upload-text {
    font-size: 17px;
    margin: 0;
  }

  input {
    display: none;
  }

  .alert {
    margin-top: 10px;
  }

  .img-loading-overlay {
    background-color: black;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.2;
    position: absolute;
  }

  .img-spinning-circle {
    display: inline-block;
    width: 64px;
    height: 64px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .img-spinning-circle:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #img-preview {
    .qq-thumbnail-wrapper {
      overflow: hidden;
      position: relative;
      height: 75px;
      border: 2px solid #e6e6e6;
      background-color: #fff;
      margin: 20px 250px;
    }
    // .file-icon {
    //   display: inline-block;
    //   float: left;
    //   width: 48px;
    //   height: 48px;
    //   margin-left: 10px;
    //   margin-top: 13.5px;
    // }
    // .img-file {
    //   background-image: url(https://demos.telerik.com/kendo-ui/content/web/upload/jpg.png);
    // }
  }
}
