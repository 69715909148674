#survey{

    .mt-15{
        margin-top: 150px;
    }
    .mb-20{
        margin-bottom: 200px;
    }

    .sp {
        width: 20px;
        height: 20px;
        clear: both;
        display: inline-block;
        margin-left: 15px;
    }

    .sp-circle {
        border: 4px rgba( #000,0.25) solid;
        border-top: 4px rgba( #000,1) solid;
        border-radius: 50%;
        -webkit-animation: spCircRot .6s infinite linear;
        animation: spCircRot .6s infinite linear;
    }
    @-webkit-keyframes spCircRot {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(359deg); }
    }
    @keyframes spCircRot {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }
}