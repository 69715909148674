.dp-form {
  h1 {
    font-weight: 700;
    margin-bottom: 30px;
  }
  .image-container {
    position: relative;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }

  label {
    font-size: 12px;
  }

  .form-text {
    font-size: 13px;
  }

  .catchphrase {
    text-align: center;
    font-size: 42px;
    position: absolute;
    color: #f1f1f1;
    padding: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    letter-spacing: 1px;
  }

  .dp-form {
    margin-top: 15px;
  }
}
.dp-errors {
  margin: 10px 0;

  p {
    margin-bottom: 5px;
  }
}

.dp-alert-padding {
  padding: 0.5rem 1.25rem !important;
}

.material-ui-form {
  width: 100%;
  margin-bottom: 10px !important;
}

.dp-validate {
  border: none;
  //font-weight: 700;
  color: #f44336;
  padding-left: 1em;
}

.dp-validate:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\F06A";
  margin-right: 5px;
}
