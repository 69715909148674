#register-section{
    padding-top: 2rem;
    #title-register{
        text-align: center;
    }
}

#register{
    #is_subscribed{
        display: inline;
    }       
}
    
 
