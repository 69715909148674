@import 'styles/main.scss';

main.main{
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

// @keyframes App-logo-spin {
//     from {
//       transform: rotate(0deg);
//     }
//     to {
//       transform: rotate(360deg);
//     }
//   }
