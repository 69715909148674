#confirmOTPPage{
    .btn-pill{
        border-radius: 50em;
        outline: 0 !important; 
        padding-top: 3px !important;
    }
    .user-otp{
        color:black; font-size: 13px;
    }
    .btn:hover{
        background-color: #c8ced3 !important;
        outline: 0 !important; 
    }
    
}