#profile{

    font-size: 0.85rem;
    .profile-err{
        color: red;
        font-style: italic;
    }

    .btn-profile:hover{
        color: black;
        background-color: white;
        border: 1px solid black;
    }
    .specialist{
       
        color: #2196f3;
        margin: 0;
    }

    .mb-6{
      margin-bottom: 6px;
    }
    .img-upload {
        margin-right: 10px;
        margin-bottom: 0;
      }

      .upload-text {
        font-size: 15px;
        margin: 0;
      }
      input {
        display: none;
      }

      .profile{
          &-info{
            font-weight: 700;
            color: #bebebe;
            text-transform: uppercase;
          }

          &-title{
            font-weight: 700;
            display: inline;
            padding-left: 10px;
            padding-bottom: 5px;
            border-radius: 5px;
          }

          &-name{
            display: inline;
            padding-left: 10px;
            padding-bottom: 2px;
            border-radius: 5px;
          }
          &-address{
            display: inline;
            padding-left: 10px;
            padding-bottom: 2px;
            border-radius: 5px;
            width: 50%;
          }
          &-type {
            border-radius: 5px;
            padding: 5px;
            font-size: 13px;
            text-transform: uppercase;
            margin: 0;
          }
      }

      .avatarWH{
          width: 193px;
          height: 195px;
      }
    
      .avatar-upload {
        position: relative;
        max-width: 205px;
        margin: auto;
        .avatar-edit {
            position: absolute;
            right: 12px;
            z-index: 1;
            top: 10px;
            input {
                display: none;
                + label {
                    display: inline-block;
                    width: 34px;
                    height: 34px;
                    margin-bottom: 0;
                    border-radius: 100%;
                    background: #FFFFFF;
                    border: 1px solid transparent;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
                    cursor: pointer;
                    font-weight: normal;
                    transition: all .2s ease-in-out;
                    &:hover {
                        background: #f1f1f1;
                        border-color: #d6d6d6;
                    }
                    &:after {
                        content: "\f040";
                        font-family: 'FontAwesome';
                        color: #757575;
                        position: absolute;
                        top: 5px;
                        left: 0;
                        right: 0;
                        text-align: center;
                        margin: auto;
                    }
                }
            }
        }
        .avatar-preview {
            // width: 192px;
            // height: 192px;
            position: relative;
            border-radius: 100%;
            border: 6px solid #F8F8F8;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            > div {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }

        .avatar-upload-text{
            cursor: pointer;
        }
        .avatar-upload-text:hover{
            color: #2196f3;
        }
    .profile-sp{
        margin-right: 15px !important;
    }
    .sp {
        width: 20px;
        height: 20px;
        clear: both;
        display: inline-block;
        margin-left: 15px;
    }

    .sp-circle {
        border: 4px rgba( #000,0.25) solid;
        border-top: 4px rgba( #000,1) solid;
        border-radius: 50%;
        -webkit-animation: spCircRot .6s infinite linear;
        animation: spCircRot .6s infinite linear;
    }
    @-webkit-keyframes spCircRot {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(359deg); }
    }
    @keyframes spCircRot {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }
}
