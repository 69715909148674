.input-field-group{
    position: relative;
    display: block;
}

.input-field-group .input-placeholder{
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 1px;
    margin: 0 10px;
    padding: 0 3px;
    line-height: .7em;
    white-space: nowrap;
    background-color: #fff;
    font-size: 0.8rem;
    color: #757575;
    pointer-events: none;
    transition: transform .15s,top .15s,font-size .15s;
    transform-origin: top left;
}

.input-field-group.large .input-placeholder{
    margin-left: 0.8rem;
    padding: 0 0.3rem;
    font-size: 0.9rem;
}
.input-field-group.large .input-field{
    height: 3rem;     
    font-size: 1rem;
    padding-left: 1rem;
}

.input-field-group.large .input-field:focus+.input-placeholder,
.input-field-group.large .input-field.has-text+.input-placeholder,
.input-field-group.large.has-error .input-field+.input-placeholder
{

    font-size: 0.8rem;
}
.input-field-group .input-field:focus+.input-placeholder,
.input-field-group .input-field.has-text+.input-placeholder,
.input-field-group.has-error .input-field+.input-placeholder
{
    transform: translateY(0);
    font-size: 0.6rem;
    top: -4px;
}

.input-field-group.has-error .input-field{
    border-color: #ed3939;
}

.input-field-group.has-error .input-placeholder{
    color: #ed3939;
}

