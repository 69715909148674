
#editableComponent{

    .editableInput{
        display: inline !important;
    }

    .btn-editable{
        margin-left: 10px;
        font-size: 12px;
       
    }

    .btn-dp{
        border: none;
        background-color: white;
        cursor: pointer;
    }

    .iconSize14{
        font-size: 14px;
    }

    .load-wrapp {
        float: left;
        width: 100px;
        height: 100px;
        margin: 0 10px 10px 0;
        padding: 20px 20px 20px;
        border-radius: 5px;
        text-align: center;
        background-color: #d8d8d8;
    }
    
    .sp {
        width: 20px;
        height: 20px;
        clear: both;
        display: inline-block;
        margin-left: 15px;
    }

    .sp-circle {
        border: 4px rgba( #000,0.25) solid;
        border-top: 4px rgba( #000,1) solid;
        border-radius: 50%;
        -webkit-animation: spCircRot .6s infinite linear;
        animation: spCircRot .6s infinite linear;
    }
    @-webkit-keyframes spCircRot {
        from { -webkit-transform: rotate(0deg); }
        to { -webkit-transform: rotate(359deg); }
    }
    @keyframes spCircRot {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
    }
    
}