#footer{
    .footerContent {

        color: #323232;
        position: relative;
        width: 100%;
        display: block;
    }

    .copyright {
        text-align: center;
    }

    .ptb-60 {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .ptb-30 {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    .social, .contact {
        font-size: 30px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline;
    }

    ul.social li, .contact ul.social li {
        display: inline-block;
        margin: 0 20px 0 0;
    }
    .spacer-15 {
        width: 100%;
        height: 15px;
    }

    a{
        text-decoration: none;
        color: black;
        font-weight: 700
    }
    .none-weight{
        font-weight: 100 !important;
    }
}