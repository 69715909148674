.register-with-social{
    margin-top: 2rem;
}
.register-code-section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    margin: 0 auto;
    max-width: 600px;
    text-align: center;


    .summary-error{
        color: #bf1515;
        margin-bottom: 1rem;
        font-size: 0.85rem;
    }

    h2{
        font-size: 1.5rem;
        margin: 2rem;
    }

    .code-form{
        width: 100%;
        max-width: 320px;
    }

    .code-input-group{
        width: 100%;
    }

    .form-action{
        margin-top: 1rem;
    }
    .submit-code-btn {
        width: 100%;
        padding: 0.7rem;
    }


    input[type="text"], input[type="number"], input[type="password"]{
        width: 100%;
        background: #fff;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        padding: 0 0.65rem;
        max-width: 100%;
        outline: 0;
        font-size: 0.65rem;
        height: 3.14286em;
    }

    a, button {
        text-decoration: none;
        transition: all .25s ease-in-out;
        cursor: pointer;
    }
    a, a:active, a:focus, button, button:active, button:focus {
        outline: 0!important;
        -moz-outline-style: none;
        box-shadow: none;
    }

    a.rounded-btn{
        border-radius: 50px;
    }

    .btn{
        width: 100%;
        cursor: pointer;
        border: none;
        color: #FFF;
        text-transform: UPPERCASE;
        padding: 0.7rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: .8px;
        margin-bottom: 15px;
        box-shadow: none;
    }
}
