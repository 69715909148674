@import 'variable.scss';

@import 'header/header.scss';
@import 'footer/footer.scss';
@import 'home/home-section.scss';
@import 'form/form.scss';
@import 'form/_upload-image.scss';
@import 'dashboard/_dashboard.scss';
@import 'profile/profile.scss';
@import 'faq/faq.scss';
@import 'editable/editable-compoent.scss';
@import 'privacy/privacy.scss';
@import 'terms/terms.scss';
@import 'survey/survey.scss';
@import 'confirm-otp/conform-otp.scss';
@import 'resgister/register-section';
@import 'resgister/register-code-section.scss';
@import 'login/_login.scss';

.btn-dp-primary {
    color: #fff;
    background: #036;
  }

  .mt-6{
    margin-top: 60px
  }

  .mt-7{
    margin-top: 70px
  }

  .mt-8{
    margin-top: 80px
  }
  .mt-9{
    margin-top: 90px
  }
  .mt-10{
    margin-top: 100px
  }
  .uppercase{
    text-transform: uppercase
  }
  .dp-font{
    font: 15px Arial, sans-serif;
  }

